.LoginForm {
  max-width: 300px;
}

.buttonContainer {
  margin-top: 12px;
  margin-bottom: 0;
}

.loginFormButton {
  width: 100%;
}