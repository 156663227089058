.pageHeader {
  background-color: white;
  margin-bottom: 16px;
}

.pageHeader > div {
  padding: 0;
}

.mainRow {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.filterButtons > :first-child {
  margin-right: 16px;
}