.sider {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
}

.logo {
  display: flex;
  height: 60px;
  justify-content: center;
  align-items: center;
  margin: 8px 0;
}

.logo > img {
  max-height: 60px;
  cursor: pointer;
}

.menuesContainer {
  display: flex;
  height: calc(100% - 80px);
  flex-direction: column;
  justify-content: space-between;
}